export function useStaticHeader(
  selector: string,
  stickyElementSelector: string,
  breakpoint: number = 310,
  gap: number = 0
) {
  const referenceHeight = ref(0);
  let resizeListener: (() => void) | undefined;
  let observer: MutationObserver | undefined;

  const setTopForFloatedMenu = () => {
    const referenceElements = Array.from(
      document.querySelectorAll("[id^=\"om-\"]")
    ).filter((el) => {
      const { id } = el;
      return /^om-[a-zA-Z0-9]+$/.test(id);
    });

    if (referenceElements.length === 0) {
      const promoBannerElements = document.querySelectorAll(".promo-banner");
      if (promoBannerElements.length > 0) {
        promoBannerElements.forEach((el) => referenceElements.push(el));
      }
    }

    referenceHeight.value =
      window.innerWidth > breakpoint
        ? Array.from(referenceElements).reduce(
          (acc, el) => acc + (el as HTMLElement).offsetHeight,
          0
        )
        : 0;

    referenceHeight.value += gap;

    const stickyElement = document.querySelector(
      stickyElementSelector
    ) as HTMLElement;
    if (stickyElement) {
      stickyElement.style.setProperty("--rh", `${referenceHeight.value}px`);
    }

    // for mobile category menu's sticky position
    const headerHeight = document.querySelector(
      ".lg-top-header-style"
    ) as HTMLElement;
    const categoryHeight = document.querySelector(
      ".lg-top-style"
    ) as HTMLElement;

    if (headerHeight && categoryHeight) {
      categoryHeight.style.setProperty(
        "--mh",
        `${headerHeight.offsetHeight + referenceHeight.value}px`
      );
    }
  };

  onMounted(() => {
    setTopForFloatedMenu();

    resizeListener = () => {
      requestAnimationFrame(setTopForFloatedMenu);
    };
    window.addEventListener("resize", resizeListener);

    setTimeout(() => {
      observer = new MutationObserver((mutationsList) => {
        requestAnimationFrame(() => {
          for (const mutation of mutationsList) {
            if (mutation.type === "childList") {
              setTopForFloatedMenu();
              break;
            }
          }
        });
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }, 0);
  });

  onBeforeUnmount(() => {
    if (resizeListener) {
      window.removeEventListener("resize", resizeListener);
    }

    if (observer) {
      observer.disconnect();
    }
  });

  return {};
}
